











































import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import moment from 'moment'

import TakeExamWindowSizeMixin from '@/mixins/takeExamWindowSize'

const takeExamModule = namespace('takeExam')

@Component
export default class TakeExamStart extends Mixins(TakeExamWindowSizeMixin) {
  @takeExamModule.Action fetchFirstQuestion!: Function
  @takeExamModule.Getter getQuestion!: any
  @takeExamModule.Getter getExamTimes!: any
  @takeExamModule.Getter('getExam') getExamProperties!: any
  @takeExamModule.Getter getExamStartedOn!: string

  hasStarted = false
  prettyTimeToStart = ''
  timeToStartEvent = 0

  created () {
    this.timeToStartEvent = setInterval(() => {
      const scheduledToMoment = moment(this.getExamTimes.scheduledTo)
      this.prettyTimeToStart = scheduledToMoment.fromNow()
    }, 1000)
  }

  start () {
    this.fetchFirstQuestion(this.$route.params.examScheduleServiceUserId)
      .then(() => {
        const question = this.getQuestion
        const { examScheduleServiceUserId } = this.$route.params
        this.$router.push({
          name: 'TakeExamQuestion',
          params: { examScheduleServiceUserId, examQuestionId: question.id }
        })
      })
  }

  get startButtonMessage () {
    const scheduledToMoment = moment(this.getExamTimes.scheduledTo)

    if (scheduledToMoment.isBefore()) {
      this.hasStarted = true
      clearInterval(this.timeToStartEvent)

      if (this.isCorrectDisplay) {
        return this.getExamStartedOn ? 'Continuar' : 'Iniciar'
      } else {
        return 'Maximize a janela'
      }
    } else {
      this.hasStarted = false
      return 'Inicia ' + this.prettyTimeToStart
    }
  }
}
